import coverImg from "../../images/webp/mezh.webp";
import { Container } from "../../components/Container";
import { Calendar as CalendarCurrent } from "../../components/Calendar";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Banner } from "../../components/Banner";
import { useParams } from "react-router-dom";
import { Box } from "grommet";
import { DefaultButton } from "../../components/DefaultButton";

export const Calendar = () => {
  const params = useParams();

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />
        <Box
          align={"start"}
          direction={"row"}
          gap={"small"}
          margin={{ bottom: "large" }}
          style={{ textAlign: "center" }}
        >
          <DefaultButton
            href={
              "https://rustriathlon.ru/uploads/f3d849420e3209f8ba74b4d5bd97de95e3e921a9.pdf"
            }
            margin={{ top: "small" }}
            label={"Скачать календарь"}
          />
        </Box>

        <CalendarCurrent event_type={params.type} />
      </Container>
    </>
  );
};
